// State
export const state = () => ({
    providerPayload: {},
    gameTypePayload: {},
})
export const mutations = {
    setProviderPayload(state, data) {
        state.providerPayload = data
    },
    setGameTypePayload(state, data) {
        state.gameTypePayload = data
    },
}
